import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { LocationProvider } from '@reach/router';
import styled from 'styled-components';
import devices from '../../../../../js/styles/Devices';

import ExtraFormFunctionalityWrapper from '../../ExtraFormFunctionalityWrapper';
import Form from '../../../../common/Form';
import Disclaimer from '../../../../utils/Disclaimer/Disclaimer';

const Wrapper = styled.div`
  && {
    position: relative;
    text-align: left;
    padding-top: 0;
    max-width: 36rem;
    margin: auto;
  }
`;

const FormBlockWrap = styled.div`
  && {
    position: relative;
    background: var(--white);
    text-align: left;
    border-radius: 4px;
    box-shadow: var(--form-shadow);
    padding-top: 0;
    max-width: 36rem;
    margin: auto;
  }
`;

const FormFrame = styled.div`
  && {
    margin: auto;
    margin-top: auto;

    @media ${devices.downFromTablet} {
      margin-top: auto;
    }

    @media ${devices.downFromMobile} {
      width: 100%;
    }
  }
`;

const FormBlock = ({
  show,
  disclaimer,
  floating,
  fullWidth,
  forwardRef,
  exampleLoan,
  title,
  partnerForm,
  prefilledApplicationForm,
  prefilledValues,
  subtitle,
  type,
  ...rest
}) => {
  if (!show) return null;

  return (
    <Wrapper>
      <FormBlockWrap
        ref={forwardRef}
        id="form"
      >
        <FormFrame>
          <ExtraFormFunctionalityWrapper>
            <LocationProvider>
              {(context) => (
                <>
                  <Form
                    key="form-block"
                    exampleLoan={exampleLoan}
                    title={title}
                    subtitle={subtitle}
                    location={context.location}
                    partnerForm={partnerForm}
                    prefilledApplicationForm={prefilledApplicationForm}
                    prefilledValues={prefilledValues}
                    type={type[0]}
                    {...rest}
                  />
                </>
              )}
            </LocationProvider>
          </ExtraFormFunctionalityWrapper>
        </FormFrame>
      </FormBlockWrap>
      <Disclaimer />
    </Wrapper>
  );
};

FormBlock.propTypes = {
  show: PropTypes.bool,
  disclaimer: PropTypes.string,
  inputSource: PropTypes.string,
  advisaSource: PropTypes.string,
  floating: PropTypes.shape(),
  fullWidth: PropTypes.bool,
  forwardRef: PropTypes.shape(),
  exampleLoan: PropTypes.shape(),
  title: PropTypes.string,
  partnerForm: PropTypes.bool,
  prefilledApplicationForm: PropTypes.bool,
  prefilledValues: PropTypes.shape(),
  subtitle: PropTypes.string,
  type: PropTypes.arrayOf(PropTypes.string),
};

FormBlock.defaultProps = {
  show: true,
  type: [''],
  disclaimer: null,
  inputSource: '',
  advisaSource: '',
  floating: {},
  fullWidth: false,
  forwardRef: null,
  exampleLoan: null,
  title: null,
  partnerForm: false,
  prefilledApplicationForm: false,
  prefilledValues: {},
  subtitle: null,
};

export default FormBlock;

export const formBlockQuery = graphql`
  fragment formBlockFields on Form {
    show
    type
    title
    subtitle
    type
    inputSource
    advisaSource
  }
`;
