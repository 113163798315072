export default {
  h1FontFamily: 'Ivar',
  textBlockMobileDisplay: 'none',
  textBlockMobileFontWeight: 'normal',
  textBlockMobileFontSizeTitle: '1.3rem',
  textBlockDesktopFontWeight: 'normal',
  textBlockDesktopFontSizeTitle: '1.3rem',
  gridGap: '0 4rem',
  campaignWrapperBackground: 'var(--transparent-dark-grey-90)',
  campaignTextColor: 'var(--white)',
  formBlockColor: 'var(--transparent-dark-grey-90)',
};
