import React, { useState, Suspense, useEffect } from 'react';

const LoadableForm = React.lazy(() => import('@advisa/onboarding-form'));

const NewFormBlock = () => {
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const initEnv = {
    pre: {},
    site: 'SE',
    brand: 'advisa',
    lang: 'sv',
    info: {
      dataPolicy: 'https://advisa.se/om-advisa/dataskyddspolicy/',
      userAgreement: 'https://advisa.se/om-advisa/anvandarvillkor/',
      calculator: {
        amount: 150000, // This and the one below are for "hardcoded" calcs I imagine
        repaymentTime: 5, // In years
        interest: 6.94,
      },
    },
    environment: 'staging', // Should be env CMS running in (staging/production)
  };

  return (
    <>
      {isMounted && (
        <Suspense fallback={<div>Loading...</div>}>
          <LoadableForm {...initEnv} />
        </Suspense>
      )}
    </>
  );
};

export default NewFormBlock;
