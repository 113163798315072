export default {
  h3FontWeight: 'var(--has-font-weight)',
  fontWeight: 'var(--has-no-font-weight)',
  fontWeightMobile: 'var(--has-font-weight)',
  bulletColor: 'var(--teal)',
  tableDisplay: 'block',
  letterSpacing: '0.03em',
  tableRowColorOne: 'var(--lightest-grey)',
  tableRowColorTwo: 'var(--transparent)',
  tableRowWeight: 'normal',
  tablePadding: '0.5em 0.8em',
  h3FontSize: '1.45rem',
  mobileFontSize: '1.6rem',
  fontSize: '3rem',
  textColor: 'var(--dark-teal)',
  borderRadius: '5px',
  borderWidth: '1px',
  borderColor: 'var(--dark-teal-20)',
  margin: '1rem 0',
  ctaTextColor: 'var(--dark-teal)',
  ctaButtonPadding: '.8em 5em',
  buttonBackgroundColor: 'var(--transparent)',
};
