export default {
  contentWrapperBgOverlay: 'linear-gradient(0deg, #1D2731 22%, rgba(29, 39, 49, 0.00) 40%)',
  mobileTitleColor: 'var(--white)',
  buttonRadius: '99999px',
  secondaryButtonBorder: '1px solid #04585D',
  primaryButtonBg: '#0FF',
  buttonTextColor: '#04585D',
  secondaryButtonMobileBg: 'var(--white)',
  trustBoxBg: '#EDFFFE',
};
