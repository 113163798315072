export default {
  authorBackground: 'transparent',
  childWidth: '100%',
  alignment: 'center',
  flexAlign: 'center',
  marginLeft: '0',
  size: '15em',
  linkColor: 'var(--teal)',
  clipPath: 'circle(35% at 50% 50%)',
  backgroundContainerDisplay: 'none',
};
