export default {
  borderRadius: '3px',
  fontWeight: 'none',
  textFontWeight: 'none',
  cardBackground: 'white',
  textColor: 'black',
  linkCardArrowColor: 'var(--mbc)',
  linkArrowSize: '1.5',
  cardTitleColor: 'var(--dark-teal)',
};
