export default {
  questionColor: 'var(--dark-teal)',
  faqIconColor: 'var(--teal)',
  borderBottom: 'solid 1px var(--transparent-black-09)',
  questionPadding: '2rem 0',
  questionJustification: 'flex-start',
  answerPadding: ' 0 0 0 1.75rem',
  FAQAnswerMargin: '2rem',
  faqColumnBorderTop: 'solid 1px var(--transparent-black-09)',
  fontSize: '3rem',
  letterSpacing: 'var(--has-no-letter-spacing)',
  marginTop: '2.5rem',
  expandButtonBorderRadius: '5px',
  borderWidth: '1px',
  borderColor: 'var(--dark-teal-20)',
  titleSizeDownLaptop: '1.6rem',
  originalSvgTransForm: '-90deg',
  buttonBackground: 'var(--transparent)',
  borderStyle: 'solid',
  svgLeft: '0',
  svgHeight: '1.5rem',
  svgColor: 'var(--mbc)',
  svgFontSize: '1rem',
  svgMarginRight: '1rem',
  svgTransform: 'rotate(-90deg)',
  svgTransformOrigin: 'center center',
  svgTransition: '0.2s ease 0s',
  svgTransformOpen: 'rotate(0deg)',
  svgOrder: '0',
  answerFontSize: '1rem',
};
