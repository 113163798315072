export default {
  topHeadlineMargin: '0',
  topPaddingDesktop: 'none',
  sectionContPaddingRight: '2rem',
  liFontSize: '1rem',
  background: 'none',
  color: 'var(--dark-teal)',
  textColor: 'var(--white)',
  bulletColor: 'var(--teal)',
  tableDisplay: 'block',
  tableAlign: 'left',
  tableHeaderColor: 'var(--lightest-grey)',
  tableHeaderBackgroundColor: 'var(--lightest-grey)',
  tablePadding: '0.5rem 0.8rem',
  tableRowColorOne: 'var(--lightest-grey)',
};
