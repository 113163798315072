export default {
  h1FontFamily: 'Ivar',
  desktopTitlePadding: '0',
  textBlockMobileDisplay: 'none',
  textBlockMobileFontWeight: 'normal',
  textBlockDesktopFontWeight: 'normal',
  campaignWrapperBackground: 'var(--transparent-dark-grey-90)',
  campaignTextColor: 'var(--white)',
  formBlockColor: 'var(--transparent-dark-grey-90)',
  mobileAspectRatio: '30 / 9',
  tabletAspectRatio: '27 / 9',
  desktopAspectRatio: '24 / 9',
  maxWrapperWidth: 'auto',
  maxWrapperWidthDownFromDesktop: 'auto',
  maxWrapperWidthUpFromLargeDesktop: 'auto',
  layoutPadding: '5rem 5% 1rem',
  topTitlePadding: '1rem',
  foregroundTranslate: 'unset',
  foregroundMobileTranslate: 'unset',
  mobileForegroundDisplay: 'none',
  bgLayerHeight: '100%',
};
