import createMarkup from '../../../../../../js/utils/formatters/html-to-text-formatter';

const createConfig = (acf, style, campaign) => (
  {
    general: {
      hero: {
        title: acf.desktopHero.title,
        subtitle: acf.desktopHero.subtitle,
        backgroundImage: acf.desktopHero.backgroundImage,
        mobileBackgroundImage: acf.mobileHero.backgroundImage,
      },
    },
    columns: [
      {
        components: [
          {
            component: 'Text',
            acf: {},
            props: {
              showComponent: 'return evaluationValues.desktop',
              fontWeight: style.textBlockDesktopFontWeight,
              fontSizeTitle: style.textBlockDesktopFontSizeTitle,
              dangerouslySetInnerHTML: createMarkup(acf.pitchText),
            },
          },
          {
            component: 'StepByStepGuideBlock',
            acf: { ...acf.guideSteps },
            props: {
              showComponent: 'return evaluationValues.desktop',
            },
          },
          {
            component: 'Campaign',
            acf: {},
            props: {
              showComponent: 'return evaluationValues.campaign',
              dangerouslySetInnerHTML: createMarkup(`${campaign?.info} <img src=${campaign?.image?.svgImage?.url?.source_url
              } width=200px />`),
            },
          },
        ],
      },
      {
        float: true,
        components: [
          {
            component: 'FormBlock',
            acf: { ...acf.form },
            props: {
              color: style.formBlockColor,
            },
          },
        ],
      },
    ],
  }
);

export default createConfig;
